<template>
  <div class="form-card">
    <div class="form-card__content">
      <div class="logo-container"
        v-if="isTransborderCustomer === 'true'">
        <img class="logo-container__logo" src="../../../assets/images/Transborder-logo.jpeg" alt="Transborder Logo" />
      </div>
      <div class="input-container">
        <label class="input-container__label" form="email">{{ $t("login.email") }} </label>
        <div class="input-container__input">
          <input type="email" class="input-email" id="email" v-model="credentials.email" />
        </div>
      </div>
      <div class="input-container">
        <label class="input-container__label" form="password">{{ $t("login.password") }} </label>
        <div class="input-container__input">
          <input :type="typeOfInput" class="input-password" v-model="credentials.password" />
          <button class="button-password" type="button" @click="changeTypeOfInput">
            <i :class="changeIcon"></i>
          </button>
        </div>
        <span class="form-card__message-error" v-show="messageError">
          {{ $t("login.messageError") }}
        </span>
      </div>
      <div class="button-content">
        <button
          @click="checkForm"
          class="button-content__button button-content__button--login"
          v-loading="loadingLogin"
        >
          Login
        </button>
      </div>
      <div class="form-card__footer">
        <div class="form-card__footer-text">
          <span>Forgot your password?</span>
          <a class="form-card__footer-link" @click="changeView('RecoverPassword')">
            Reset it</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import showPassword from "@/Extend/ShowPassword";
import Login from "../../../Extend/Login";

export default {
  name: "FormCard",
  mixins: [showPassword, Login],
  props: {
    isTransborderCustomer: String
  },
  data() {
    return {
      credentials: {
        email: "",
        password: "",
      },
      loadingLogin: false,
      messageError: false,
    };
  },
  methods: {
    async checkForm() {
      if (!this.credentials.email || !this.credentials.password) {
        this.messageError = true;
        return;
      }
      this.messageError = false;
      this.loadingLogin = true;
      await this.auth();
    },
    changeView(routeName) {
      this.$router.push({
        name: routeName,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.form-card {
  @include card-styles;
  border-radius: 25px;
  box-shadow: 2px 2px 4px #6b7489;
  background-color: $color-white;
  @extend %flex-col-center-center;
  padding: 30px;
  width: 520px;
  background-color: $color-white;
  @media (max-width: 790px) {
    width: 90%;
  }
  &__content {
    font-family: $font-family-portal;
    width: 60%;
    margin: auto;
    display: flex;
    flex-direction: column;
    @media (max-width: 490px) {
      width: 80%;
    }
  }
  &__footer {
    display: flex;
    flex-direction: column;
    &-text {
      font-size: 14px;
      margin: 4px auto;
    }
    &-link {
      color: $color-gray;
      cursor: pointer;
    }
  }
  &__message-error {
    color: red;
    font-weight: bold;
    margin: 12px auto;
    font-size: 11px;
    position: absolute;
    left: 0;
    width: 100%;
    top: 75px;
    font-size: 0.78rem;
    i {
      color: red;
    }
  }
}
.button-content {
  width: 100%;
  margin-top: 8px;
  &__button {
    border: none;
    border-radius: 10px;
    margin: 10px auto;
    padding: 6px;
    width: 100%;
    font-size: 14px;
    font-weight: bold;
    &--login {
      background: $color-primary-company;
      color: $color-white;
    }
    &--request {
      background: $color-gray;
      color: black;
    }
  }
}
.input-container {
  width: 100%;
  position: relative;
  &__label {
    @include label-input-information;
    justify-content: center;
  }
  &__input {
    @include input-information;
    text-align: center;
    width: 100%;
    &:hover {
      box-shadow: none;
    }
    &:focus-within {
      box-shadow: none;
    }
  }
}
.input-email {
  @include input-password;
  line-height: 0;
  margin: none;
  width: 100%;
  padding: 0px 10px;
  text-align: center;
}
.input-password {
  @include input-password;
  line-height: 0;
  margin: none;
  padding: 0px 10px;
  margin-left: 37px;
  text-align: center;
}
.button-password {
  @include icon-input;
  @extend %flex-center-center;
  margin-right: 0.313rem;
}
.logo-container {
  &__logo {
    margin: 1.5rem 0;
    height: 100px;
    width: 200px;
  }
}
</style>
